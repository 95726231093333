import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/SD_Card_Access/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "SD Card Access - CuteFTP",
  "path": "/Motion_Detection/SD_Card_Access/CuteFTP/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Access the Camera Recordings on the internal SD Card",
  "image": "./MD_SearchThumb_SDCardAccess_CuteFTP.png",
  "social": "/images/Search/MD_SearchThumb_SDCardAccess_CuteFTP.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-SD-Card_white.webp",
  "chapter": "Motion Detection"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='SD Card Access - CuteFTP' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Access the Camera Recordings on the internal SD Card' image='/images/Search/MD_SearchThumb_SDCardAccess_CuteFTP.png' twitter='/images/Search/MD_SearchThumb_SDCardAccess_CuteFTP.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/SD_Karten_Zugriff/CuteFTP/' locationFR='/fr/Motion_Detection/SD_Card_Access/CuteFTP/' crumbLabel="CuteFTP" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "cuteftp---ftp-access-to-the-internal-sd-card",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#cuteftp---ftp-access-to-the-internal-sd-card",
        "aria-label": "cuteftp   ftp access to the internal sd card permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CuteFTP - FTP Access to the internal SD Card`}</h2>
    <p>{`CuteFTP for Windows is a paid FTP client that you can use to access your cameras SD card. We recommend you to download the latest version from `}<Link to="http://www.cuteftp.com/trial.aspx" target="_blank" rel="noopener noreferrer" mdxType="Link">{`here`}</Link>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "729px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a19d79e77413cf0c47029b0bba9830ba/b2982/CuteFTP-SD_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "102.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACcklEQVQ4y42RW3OaQBiG+f+X6UU7k4vcKAsLYtLUaKpNazKNaTxVFNRdwAOigkZWQTGJQCca07Q5PvPOzu7MPvPOfkvF4gJkIQdZDkLIsgzDsCxkGYamowAAlmWi0SjkOD4Wg7u79M4O+PCBAeDo08fTvT0KyxXclC5rnZJYx7gmSyK+LGgY67puWRZCSNf1Qb8/MG1LrluiOJFqxBo718t2r0eVynIV4aokqWrXJrZNJncLsUejUafdyWazudxFLperVMSSKBZ+V8R6PXdRyufz5WKRKubPJalYFeuojjHSVKWlKm3UUHrGECElGo2waxgGMAAAGgCavjsAkEgkqOFAX3qmhpqGhg2taXZUs6sZKvLIaDjo0jTgeZ7juM36sIEQJpNJanw1NmoKjJwKfJFmCvuH8v6hHD+oCQfyZzYL6Qi31R7zV9alZmQvLcBfwn71S6qZOEaJY3T0VTuK/WTp6GuyaVpzd4LEfA83+yqa2+aCWHPbvHEn3RamAeBfkQfDYRjcEvuKTF0ydZz5YpMf2TNBiHMQcs+xlQeDMAyDIAj/JZ1O0zT9bO3/su/7wZaNnMlkGIZ5l/y0OZPJgBce/ET2/TAI7vN+ub9tDsLQXydYJ3PyjYFsLC7wQuxpuBifTKWomlgWy2f5yxOlcK6cfK8lU/0mMntGq9FQJEmV5ZdSLZcp3Ki79sgeDxfjkWMYU8PwCPGm06XjXLvus1k6zmrhaQhTrXb7YWC3q9XtahW+xeZyp9ulNE27+6rVKgyC6zX3I3gZ3/fDMOx2OlT7UfPNmvB9KIpCyZJEbDIyLULIzJnNZrP5W7iu63keQugPJEjhcSERjlMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a19d79e77413cf0c47029b0bba9830ba/e4706/CuteFTP-SD_01.avif 230w", "/en/static/a19d79e77413cf0c47029b0bba9830ba/d1af7/CuteFTP-SD_01.avif 460w", "/en/static/a19d79e77413cf0c47029b0bba9830ba/e92f3/CuteFTP-SD_01.avif 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a19d79e77413cf0c47029b0bba9830ba/a0b58/CuteFTP-SD_01.webp 230w", "/en/static/a19d79e77413cf0c47029b0bba9830ba/bc10c/CuteFTP-SD_01.webp 460w", "/en/static/a19d79e77413cf0c47029b0bba9830ba/78e23/CuteFTP-SD_01.webp 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a19d79e77413cf0c47029b0bba9830ba/81c8e/CuteFTP-SD_01.png 230w", "/en/static/a19d79e77413cf0c47029b0bba9830ba/08a84/CuteFTP-SD_01.png 460w", "/en/static/a19d79e77413cf0c47029b0bba9830ba/b2982/CuteFTP-SD_01.png 729w"],
              "sizes": "(max-width: 729px) 100vw, 729px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a19d79e77413cf0c47029b0bba9830ba/b2982/CuteFTP-SD_01.png",
              "alt": "CuteFTP - FTP Access to the internal SD Card",
              "title": "CuteFTP - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-1",
        "aria-label": "step 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1`}</h3>
    <p>{`Choose to connect to a new HTTP site.`}</p>
    <p><img parentName="p" {...{
        "src": "./CuteFTP-SD_02.png",
        "alt": "CuteFTP - FTP Access to the internal SD Card"
      }}></img></p>
    <h3 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h3>
    <p>{`The `}<strong parentName="p">{`host address`}</strong>{` is your cameras local IP or DDNS address. Type in your camera login below.`}</p>
    <p><img parentName="p" {...{
        "src": "./CuteFTP-SD_03.png",
        "alt": "CuteFTP - FTP Access to the internal SD Card"
      }}></img></p>
    <h3 {...{
      "id": "step-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-3",
        "aria-label": "step 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3`}</h3>
    <p>{`Choose `}<strong parentName="p">{`/sd`}</strong>{` as your default source directory.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "731px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c6aaf82a4f59e50615f914db6465238a/6e9ba/CuteFTP-SD_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "101.73913043478262%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC0klEQVQ4y42TWW/bRhRG+f+f8hY4TuB4ESXFcoymDdKiTdoYkEjJEiVxESmK+zIc7uRwkclYYiEJTt0CdnMwGFwMcPDh3pnBLGgDB0IIXddxHOBC6Pq+bduSJCmqohuGrMgAAM/3nJVozubWfA4MQ1guZVnGoK3bGq9rJi0omqGyC3p5eysuFoZpuhAKgqDruq7pFvBtmgEU5U2nEfTiNEUIYapmz2hZWMq6xhkaY6i8qZuWDURxxXEsSZCj4Q6G5aYcR9EMu1yObufD0VDVVExVBIIccHPSXlwZ3Hsof3XMpQ/Ej7/8dPr2tI3jeKuFt3D87KxzdtrpdNrt9rtu9/z8vN/vY3kWN/eoWt/V9aau698//9ntvetedt+cnLx6/ebo+Pjo+PXRyUn75csPL150rnq9y8ter4fjOEmSWJ7nTdNst9umafKioAcEfdNfEENvJek0o9NMpKqOIMaKQt7ctHC8twfHcYIg/i2v18rHT/Jvf2h/3YA+AfpEMKYKlotm9GbOjD/83Op2npaLQmM5aTQWiOFiQCqTmTFnTZoFHI80YzwYXDyTnGWZBYAfRm4QekHo+gGArh9G5V31bbudTKcXFxdPygghCGHTNJv7++1ms1v788NOTSY/JG8f+F7vZIp6Ts7zXNN0XdUt0yr21HX9T/KT8mZz6NkwjDAIPc9LkiRN07Jc/2gy2iVrnuu50I/jBKUIpVl1V32XW+321fX11fV1u9PZPRLL0Gbjr7ejLxJFsJ9+3X0J3YQORAglSYIQStM0DKOyXEuiyM9mCs/LPC8yjKWqGAQART6K/CqJXVmeUPPZQmKXirhjxfM8w7CStGIYhmWYNUJ1UdRFUeV5EoZYEATNA3Vd+34QxUkYxdGe+IEoihBCzSOyPMd8zztc6UE+jPcpto/Isuy/yVVVPWM+rqMowizTch13P5IyyzKEUFmWxf+R53kQBH8DGIAZT08piWkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c6aaf82a4f59e50615f914db6465238a/e4706/CuteFTP-SD_04.avif 230w", "/en/static/c6aaf82a4f59e50615f914db6465238a/d1af7/CuteFTP-SD_04.avif 460w", "/en/static/c6aaf82a4f59e50615f914db6465238a/8ebaf/CuteFTP-SD_04.avif 731w"],
              "sizes": "(max-width: 731px) 100vw, 731px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c6aaf82a4f59e50615f914db6465238a/a0b58/CuteFTP-SD_04.webp 230w", "/en/static/c6aaf82a4f59e50615f914db6465238a/bc10c/CuteFTP-SD_04.webp 460w", "/en/static/c6aaf82a4f59e50615f914db6465238a/feeb6/CuteFTP-SD_04.webp 731w"],
              "sizes": "(max-width: 731px) 100vw, 731px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c6aaf82a4f59e50615f914db6465238a/81c8e/CuteFTP-SD_04.png 230w", "/en/static/c6aaf82a4f59e50615f914db6465238a/08a84/CuteFTP-SD_04.png 460w", "/en/static/c6aaf82a4f59e50615f914db6465238a/6e9ba/CuteFTP-SD_04.png 731w"],
              "sizes": "(max-width: 731px) 100vw, 731px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c6aaf82a4f59e50615f914db6465238a/6e9ba/CuteFTP-SD_04.png",
              "alt": "CuteFTP - FTP Access to the internal SD Card",
              "title": "CuteFTP - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-4",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-4",
        "aria-label": "step 4 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 4`}</h3>
    <p>{`Choose `}<strong parentName="p">{`HTTP`}</strong>{` as protocol and type in your cameras web port.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d4c6448dc15039d6c6de30e9cceb0b22/37523/CuteFTP-SD_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC9UlEQVQ4y52SyY7bRhCG+cZ+DCfwaUYZSr4lByPINYcACcZIxiMrXkYSJVnLcOlmr9xaXESRIkU2O5Dl2EbsDJD8KDQKjfrqry609sOPz/T+4OLicqD39Su91/tO1/uXvZ6u63pff/p0oPcHfb2v61eDy97FN99ePXkyGAy+f/z410ePtJvRyLlf3C/X08V6Njdm7ybzu7vVeGxaFsbo3nGRvXQhgNiDo1t4/Ru8fo7m8+VmYyzm2syYIcQApLZLuccohevV2jFtF9PADzYbaz4dW6aFME3S2BNCZLttnAZ+RBnWRi9vhsPR61cv+eonaDzzrV+E74SeybHz5nb15sXmz99X5pKIaMspQSZACLousxwGLKCtlzPCXWhDAkDke3VVHo9Vc6yzNIyFKIpDdaiLfZkkqYiIjwiAGwtgh4QYQs0yTaWUlJ36pFNe7n0EzO02U0p53Kcu9VkopTwUVXWolFIAQM18D3fdP+H9DjJiJmmulIrCSPiRR3nbtB+LHdv5dziDjNhptldKiTASQRTyoG2l6pSU8gQ7D8IYrpNkp5TilPnUo5A0nznbDzjnqQOshRDpGfYwIwB/PjZj/IE3uwTdb+PTwkI/DLnPMW/bT3Acxw+NTdxNvE2llL7nBdznhNV1LaU8F4dh+AH+cNV15/PUeBtu1msAIMbYNG3LtO83puu6lLIkiZMkgdDVLMv6wvmkLDtg3NS1TJMWu9JjkhJJkPS9Lkm6qlJCRNpyuSzKfJtG2S4p4iQX4pDnx+MxjrcYU8YYpQwhijFDiHAeJMkujrM0zThn2njydvzu5vnw59d31+DV7eqPa3P6FjOX8VO4yHaxzX1EKKAcch+dg3kIuLa2WCyyLN9nRVlUUimpVJ7vi6I8Nm1V1fku32W7VsqiKKuqbqVs2rZpW9l1SZpqk8mEEHL+NP9JuzzXDMMIguB/wHmea1PDIITE77f/pcqyrL+mpmmEENrwxXA+nc2MGfiaMCaUsr+DfswZ47Zt/wUAsXm2Agp6UgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d4c6448dc15039d6c6de30e9cceb0b22/e4706/CuteFTP-SD_05.avif 230w", "/en/static/d4c6448dc15039d6c6de30e9cceb0b22/d1af7/CuteFTP-SD_05.avif 460w", "/en/static/d4c6448dc15039d6c6de30e9cceb0b22/f7eb3/CuteFTP-SD_05.avif 720w"],
              "sizes": "(max-width: 720px) 100vw, 720px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d4c6448dc15039d6c6de30e9cceb0b22/a0b58/CuteFTP-SD_05.webp 230w", "/en/static/d4c6448dc15039d6c6de30e9cceb0b22/bc10c/CuteFTP-SD_05.webp 460w", "/en/static/d4c6448dc15039d6c6de30e9cceb0b22/bc514/CuteFTP-SD_05.webp 720w"],
              "sizes": "(max-width: 720px) 100vw, 720px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d4c6448dc15039d6c6de30e9cceb0b22/81c8e/CuteFTP-SD_05.png 230w", "/en/static/d4c6448dc15039d6c6de30e9cceb0b22/08a84/CuteFTP-SD_05.png 460w", "/en/static/d4c6448dc15039d6c6de30e9cceb0b22/37523/CuteFTP-SD_05.png 720w"],
              "sizes": "(max-width: 720px) 100vw, 720px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d4c6448dc15039d6c6de30e9cceb0b22/37523/CuteFTP-SD_05.png",
              "alt": "CuteFTP - FTP Access to the internal SD Card",
              "title": "CuteFTP - FTP Access to the internal SD Card",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "step-5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#step-5",
        "aria-label": "step 5 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 5`}</h3>
    <p>{`Click on `}<strong parentName="p">{`Connect`}</strong>{` to start browsing your recordings.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      